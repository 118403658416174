import React, { Component } from 'react';
import { SocialButtons } from './SocialButtons';

export class Header extends Component {
    render() {
      return(
          <div style={{
              alignItems: "center",
              textAlign: "center",
              backgroundColor: 'black',
              padding: 30
          }}>
              <div style={{
                  backgroundColor: 'black', 
              }}>
                <img src={process.env.PUBLIC_URL + '/logo.png'} className="logo" style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 115
                }} />
                <SocialButtons />
                </div>
          </div>
      )
    }
  }