import React, { Component } from 'react';

export class Videos extends Component {
  render() {
    return(
        <div style={{
            padding: 30,
            backgroundColor: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>
        <div style={{
            maxWidth: 1700,
            textAlign: "center"
          }}>
             <iframe width="560" height="315" src="https://www.youtube.com/embed/y0cIGqoyQ0A" title="Ocean waves" frameborder="0" ></iframe>
             <iframe width="560" height="315" src="https://www.youtube.com/embed/MT5i2jkCR48" title="Sunny day" frameborder="0" ></iframe>
             <iframe width="560" height="315" src="https://www.youtube.com/embed/MnVjpSPT8Rk" title="Mountains in the clouds" frameborder="0"></iframe>
             <iframe width="560" height="315" src="https://www.youtube.com/embed/Ayi7894bTbA" title="Sun on the snow" frameborder="0"></iframe>
             <iframe width="560" height="315" src="https://www.youtube.com/embed/kVd4HdGy9ok" title="Boggles the mind" frameborder="0"></iframe>
             <iframe width="560" height="315" src="https://www.youtube.com/embed/D61V7haw91w" title="Solitude" frameborder="0"></iframe>
        </div>
        </div>
    )
  }
}