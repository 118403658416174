import React, { Component } from 'react';

export class DistributedPlatforms extends Component {
  render() {
    return(
        <div style={{
            padding: 30,
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>
        <div style={{
            maxWidth: 1700,
            textAlign: "center"
        }}>
            
        </div>
            <iframe frameborder="0" height="450" src="https://embed.music.apple.com/us/album/carpathian-dreams/1573212725"></iframe>
        </div>
    )
  }
}