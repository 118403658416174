import * as React from 'react'; 
import { Videos } from './Videos';
import { Header } from './Header';
import { DistributedPlatforms } from './DistributedPlatforms';
import './App.css'

function App() {
  return (
    <div>
       <div>
        <Header />
      </div>
      <div>
        <DistributedPlatforms />
      </div>
      <div>
        <Videos />
      </div>
    </div>
  );
}

export default App;
