import React, { Component } from 'react';
import {ReactComponent as FacebookIcon} from './icons/facebook-icon.svg';
import {ReactComponent as InstagramIcon} from './icons/instagram-icon.svg';
import {ReactComponent as YouTubeIcon} from './icons/youtube-icon.svg';
import {ReactComponent as TikTokIcon} from './icons/tiktok-icon.svg';
import {ReactComponent as SpotifyIcon} from './icons/spotify-icon.svg';
import {ReactComponent as AppleMusicIcon} from './icons/apple-music-icon.svg';
import './SocialButton.css'
import { maxWidth, textAlign } from '@mui/system';

export class SocialButtons extends Component {

  render() {
    return(
        <div style={{
            alignContent: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 20
        }}>
       <div style={{
           display: 'flex',
           justifyContent: "space-between",
           textAlign: "center",
       }}>
           <a href='https://www.facebook.com/kerzemann/' target="_blank"><FacebookIcon className='social_icon' /> </a>
           <a href='https://www.instagram.com/kerzemann/' target="_blank"><InstagramIcon className='social_icon'/> </a>
           <a href='https://www.youtube.com/channel/UC2sJJxld46nr4ycSCQokFvg' target="_blank"><YouTubeIcon className='social_icon'/></a>
           <a href='https://www.tiktok.com/@kerzemann' target="_blank"><TikTokIcon className='social_icon'/></a>
           <a href='https://open.spotify.com/artist/2AIbPjBIqru2eiI73rlyFk' target="_blank"><SpotifyIcon className='social_icon'/></a>
           <a href='https://music.apple.com/us/artist/kerze-mann/1551307245' target="_blank"><AppleMusicIcon className='social_icon'/></a>
       </div>
       </div>
    )
  }
}